/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Terminal Grotesque";
  src: url("./fonts/terminal-grotesque.ttf") format("truetype"),
    url("./fonts/terminal-grotesque_open.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

html {
  overflow-y: scroll;
}
